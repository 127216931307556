import React, { useContext } from 'react';

import './JobSeekerSidebar.css';
import { AppContext } from '../../AppContext';
import { Divider, Tag, Typography } from 'antd';

const {  Text } = Typography;

const planType =(plan)=>{
  switch (plan) {
    case 'ultimate_job_seeking_plan':
      return 'ULTIMATE PLAN'
    case 'passive':
        return 'PASSIVE PLAN'
    default:
      break;
  }
  if (plan=='ultimate_job_seeking_plan'){
    return 'ULTIMATE PLAN'
  }
}


const JobSeekerSidebar = () => {
  const { currentJobSeeker } = useContext(AppContext);

  return (
    <div className="JobSeekerSidebar">
      <div className="jobseeker-sidebar-header">
        Profile:
      </div>
      <div className="jobseeker-profile-container">
        <div className="profile-image" style={{
      backgroundSize: 'cover',
      backgroundPosition: 'center',backgroundImage: `url(${currentJobSeeker.photoURL})`}}>
          {/* <img src={currentJobSeeker.photoURL}/> */}
        </div>
        <div>
          <div>
              <div className="jobseeker-name">
                  {currentJobSeeker.name}
              </div>
          </div>
         
        </div>
       
      </div>
      <Divider />

      <div className="jobseeker-sidebar-header">
        Jobs applied to:
      </div>
      <div className='jobseeker-membership'>
      <Tag color='blue' >
        {planType(currentJobSeeker.membershiptype)}
      </Tag>
      <div className='jobseeker-jobsapplied'>{currentJobSeeker.jobsappliedto} / 300</div>
      
      </div>

      <Divider />
      <div className="jobseeker-sidebar-header">
        Details:
      </div>
      <div>
            <Text>
               <a href={currentJobSeeker.email}>E-mail Address</a>
            </Text>
          </div>
            <div>
                <Text>
                <a href={currentJobSeeker.resume}>Resume</a>
                </Text>
            </div>
            <div>
                <Text>
                <a href={currentJobSeeker.linkedinprofilelink}>LinkedIn Profile</a>
                </Text>
            </div>

            <Divider />
            <div className="jobseeker-sidebar-header">
        Roles to apply for:
      </div>
      <ul>
        {currentJobSeeker.rolestoapplyfor.map((item) => {
          const role = item;
          return (
            <>
              <li>{role}</li>
            </>
          );
        })}
      </ul>
      <Divider />
      <div className="jobseeker-sidebar-header">
        Locations:
      </div>
      <ul>
      {currentJobSeeker.location.cities.map((location, index) => {
  return (
    <li key={index}>
      {location.city}, {location.state}
    </li>
  );
})}
      </ul>
    </div>
  );
};

JobSeekerSidebar.propTypes = {};

JobSeekerSidebar.defaultProps = {};

export default JobSeekerSidebar;
