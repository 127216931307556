import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { Table, Tag } from 'antd';
import './JobListings.css';

const JobListings = (props) => {
  const { currentJobSeeker } = useContext(AppContext);
    // Check for no data
    if (!currentJobSeeker || !currentJobSeeker.jobsapplied) {
      return <div className="JobListings">No job listings available.</div>;
    }
  let columns = [];
  if (props.admin == 'true') {
    columns = [
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: (text, record) => <a href={record.linktojob}>{text}</a>
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company'
      },
      {
        title: 'Date Applied',
        dataIndex: 'dateappliedto',
        key: 'dateappliedto'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          let color = 'red';
          let message = 'Need More Info';
          if (status === 'applied') {
            color = 'green';
            message = 'Applied';
          }
          return <Tag color={color}>{message}</Tag>;
        }
      },
      {
        title: 'Recruiter',
        dataIndex: 'recruiter',
        key: 'recruiter'
      }
    ];
  } else {
    columns = [
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: (text, record) => <a href={record.linktojob}>{text}</a>
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company'
      },
      {
        title: 'Date Applied',
        dataIndex: 'dateappliedto',
        key: 'dateappliedto'
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          let color = 'red';
          let message = 'Need More Info';
          if (status === 'applied') {
            color = 'green';
            message = 'Applied';
          }
          return <Tag color={color}>{message}</Tag>;
        }
      }
    ];
  }

  const data = currentJobSeeker.jobsapplied;
  const dataSource = Object.entries(data).map((item) => {
    const job = item[1];
    return {
      key: item[0],
      ...job
    };
  });

  return (
    <div className="JobListings">
      <Table dataSource={dataSource} columns={columns} size="middle" className="joblisting-table" />
    </div>
  );
};

export default JobListings;
