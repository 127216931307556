import React from 'react';

import './RecruiterView.css';
import { Layout } from 'antd';
import LandingPageHeader from '../LandingPageHeader/LandingPageHeader';
import JobSeekerSidebar from '../JobSeekerSidebar/JobSeekerSidebar';
import JobListings from '../JobListings/JobListings';

const { Header, Footer, Sider, Content } = Layout;

const headerStyle = {
  backgroundColor: '#fff',
  lineHeight: '0px'
};
const contentStyle = {
  //minHeight: 120,
  // lineHeight: '120px',
  color: '#fff',
  backgroundColor: '#fff'
};
const siderStyle = {
  backgroundColor: '#fff'
};
const footerStyle = {
  color: '#fff',
  backgroundColor: '#7dbcea'
};

const RecruiterView = () => (
  <div className="RecruiterView">
    <Layout>
      <Header style={headerStyle}>
        <LandingPageHeader />
      </Header>
      <Layout hasSider>
        <Sider style={siderStyle}>
          <JobSeekerSidebar />
        </Sider>
        <Content style={contentStyle}>
          <JobListings admin="true  " />
        </Content>
      </Layout>
      <Footer style={footerStyle}>Footer</Footer>
    </Layout>
  </div>
);

RecruiterView.propTypes = {};

RecruiterView.defaultProps = {};

export default RecruiterView;
