import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAFhl9SSmN0YjBIigToubAfhF9xdYmXmgY',
  authDomain: 'applystorm-de9c1.firebaseapp.com',
  databaseURL: 'https://applystorm-de9c1-default-rtdb.firebaseio.com/',
  projectId: 'applystorm-de9c1',
  storageBucket: 'applystorm-de9c1.appspot.com',
  messagingSenderId: '1026762939606',
  appId: '1:1026762939606:web:a2a55ffa96f4f4e38afd77',
  measurementId: 'G-HF5S783BDL'
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
