import React from 'react';

import './HowItWorksSection.css';
import hiwhero from '../../images/hiw-hero.svg';
import hiwstep1 from '../../images/HIW_1.svg';
import hiwstep2 from '../../images/HIW_2.svg';
import hiwstep3 from '../../images/HIW_3.svg';

const HowItWorksSection = () => (
  <div className="HowItWorksSection" id='howitworks'>
    <div className="main-header"> How ApplyStorm Works. </div>
    <div className="main-subtitle">
      We take great pride in delivering everything needed to land your next job opportunity. It’s
      easy to get started. See How the process works below
    </div>

    <div className="hiw-container">
      <div>
        <img src={hiwhero} alt="How ApplyStorm works." height={265.31} width={291} />
      </div>
      <div className="hiw-subcontainer-right">
        <div className="hiw-stepcontainer">
          <div className="hiw-step-img">
            <img src={hiwstep1} alt="How ApplyStorm works." height={50.06} width={50.06} />
          </div>
          <div>
            <div className="hiw-step-title">Create Profile.</div>
            <div className="hiw-step-subtitle">
              Share details about the types of roles you are seeking to help you land your next
              opportunity.
            </div>
          </div>
        </div>
        <div className="hiw-stepcontainer">
          <div className="hiw-step-img">
            <img src={hiwstep2} alt="How ApplyStorm works." height={50.06} width={50.06} />
          </div>
          <div>
            <div className="hiw-step-title">Meet your Recruiter.</div>
            <div className="hiw-step-subtitle">
              Join a brief 1:1 recruiter session to get to know more about how Apply Storm can work
              for you.
            </div>
          </div>
        </div>
        <div className="hiw-stepcontainer">
          <div className="hiw-step-img">
            <img src={hiwstep3} alt="How ApplyStorm works." height={50.06} width={50.06} />
          </div>
          <div>
            <div className="hiw-step-title">Schedule your interviews.</div>
            <div className="hiw-step-subtitle">
              Sit back and wait for the interview requests to roll into your email inbox.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HowItWorksSection;
