import React from 'react';
import './PricingPage.css';
import StripePricingTable from '../PricingTable/PricingTable';

const PricingPage = () => (
  <div className="PricingPage" id='pricing'>
    <div className="pricing-container">
      <div className="main-header">Frustration Free Pricing.</div>
      <div className="main-subtitle">
        Keeping an active search is important. Select a plan that works for you.
      </div>
    </div>
    <StripePricingTable />
  </div>
);

export default PricingPage;
