import { database, dbref, dbOnValue } from './dataobject';
//import axios from 'axios';
import 'firebase/compat/auth';
import 'firebase/compat/database';
//import {twilioConfig,helpMessage} from './constants'

export const getJobSeekers = () => {
  return new Promise((resolve, reject) => {
    const datapath = 'jobseekers';
    const currentJobSeekers = dbref(database, datapath);
    const jobSeekers = [];

    dbOnValue(
      currentJobSeekers,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // Convert the object of users into an array of user info objects
          const jobSeekersInfo = Object.values(data).map((user) => user);
          jobSeekers.push(...jobSeekersInfo);
        }
        // Resolve the promise with the users' info
        resolve(jobSeekers);
      },
      (error) => {
        // Reject the promise if there's an error
        reject(error);
      }
    );

    return jobSeekers;
  });
};
