import React from 'react';
import './PersonalRecuiterSection.css';
//import { Image } from 'antd';
import hero from '../../images/personal-recruiter.svg';

const PersonalRecuiterSection = () => {
  // const img = require('../../images/personal-recruiter.svg')
  return (
    <div className="PersonalRecuiterSection">
      <div className="main-header">Say hello to your own personal recruiter.</div>
      <div className='subtitle'>
        With our comprehensive application dashboard, you can see what roles your recruiter has
        applied you for in realtime.
      </div>

      <div>
        <img
          src={hero}
          alt="Say hello to your own personal recruiter."
          height={334.19}
          width={545}
        />
      </div>
    </div>
  );
};

export default PersonalRecuiterSection;
