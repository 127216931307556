import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import './SignUp.css';
import 'firebase/compat/auth';
import { useNavigate } from 'react-router-dom';

const SignUp = ({ recruiter }) => {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const recruiterSignupTitle = (recruiter) => {
    if (recruiter) {
      return <>Recruiter Sign Up</>;
    } else {
      return <>Sign Up</>;
    }
  };

  const handleGoogleSignUp = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const currentUser = result.user;

      if (currentUser) {
        console.log(currentUser.email);

        await createFirebaseUserDetails(currentUser, recruiter);

        if (recruiter) {
          navigate('/recruiterview');
        } else {
          navigate('/jobseekerview');
        }
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div id="signup" className="signup-container">
      <h2>{recruiterSignupTitle(recruiter)}</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleGoogleSignUp}>Sign Up with Google</button>
    </div>
  );
};

export default SignUp;

async function createFirebaseUserDetails(currentUser, recruiter) {
  const userDetails = {
    email: currentUser.email,
    displayName: currentUser.displayName,
    photoURL: currentUser.photoURL,
    uid: currentUser.uid,
    role: recruiter ? 'recruiter' : 'jobseeker'
  };

  if (recruiter) {
    await firebase.database().ref(`users/${currentUser.uid}/info`).set(userDetails);
  } else {
    const jobSeekerDetails = {
      email: currentUser.email,
      displayName: currentUser.displayName,
      photoURL: currentUser.photoURL,
      uid: currentUser.uid,
      role: recruiter ? 'recruiter' : 'jobseeker',
      coverletter: 'https://docs.google.com',
      salaryrange: '$100,000 - $200,000',
      jobsapplied: {},
      jobsappliedto: 0,
      linkedinprofilelink: 'https://www.linkedin.com/in/',
      location: {
        cities: [{ city: 'San Francisco', state: 'CA' }],
        radius: 25
      },
      membershiptype: 'passive',
      name: currentUser.displayName,
      resume: 'https://docs.google.com',
      rolestoapplyfor: ['Any Job'],
      status: 'unworked'
    };

    // // Example of adding a new 'job applied' record
    // emptyUserProfile.jobsapplied['newJobID'] = {
    //   company: "",
    //   dateappliedto: "",
    //   linktojob: "",
    //   recruiter: "",
    //   role: "",
    //   status: ""
    // }

    await firebase.database().ref(`users/${currentUser.uid}/info`).set(userDetails);
    await firebase.database().ref(`jobseekers/${currentUser.uid}`).set(jobSeekerDetails);
  }
}
