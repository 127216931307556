// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase, onValue, ref, set, push, child } from 'firebase/database';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

export const firebaseConfig = {
  apiKey: 'AIzaSyAFhl9SSmN0YjBIigToubAfhF9xdYmXmgY',
  authDomain: 'applystorm-de9c1.firebaseapp.com',
  databaseURL: 'https://applystorm-de9c1-default-rtdb.firebaseio.com/',
  projectId: 'applystorm-de9c1',
  storageBucket: 'applystorm-de9c1.appspot.com',
  messagingSenderId: '1026762939606',
  appId: '1:1026762939606:web:a2a55ffa96f4f4e38afd77',
  measurementId: 'G-HF5S783BDL'
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);
export const dbset = set;
export const dbref = ref;
export const dbpush = push;
export const dbOnValue = onValue;
export const dbChild = child;
firebase.initializeApp(firebaseConfig);

export default firebase;
