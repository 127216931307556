import React, { useState, useContext } from 'react';
import './Login.css';
import firebase from '../../utils/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../AppContext';

const Login = () => {
  const { setAppUser } = useContext(AppContext);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      const user = result.user;

      setAppUser(user);
      // Navigate only after AppUser is set
      if (user) {
        navigate('/mood');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleGoogleLogin}>Login with Google</button>
      <p>
        If you do not have an account, please <a href="/signup">Sign Up Here </a>
      </p>
    </div>
  );
};

export default Login;
