import React, { useEffect } from 'react';
import { Button } from 'antd';
import './LandingPage.css';
import LandingPageHeader from '../LandingPageHeader/LandingPageHeader';
import PersonalRecuiterSection from '../PersonalRecuiterSection/PersonalRecuiterSection';
import PricingPage from '../PricingPage/PricingPage';
import landingpageimage from '../../images/header-image@2x.svg';
import FrustratedJobseekerSection from '../FrustratedJobseekerSection/FrustratedJobseekerSection';
import SeeDailyProgressSection from '../SeeDailyProgressSection/SeeDailyProgressSection';
import HowItWorksSection from '../HowItWorksSection/HowItWorksSection';

const LandingPage = () => {
  useEffect(() => {
    // Google Analytics
    // Creating the script tag for gtag.js
    const gtagScript = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-HF5S783BDL';
    document.head.appendChild(gtagScript);

    // Inline script to initialize dataLayer and configure gtag
    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-HF5S783BDL');
    `;
    document.head.appendChild(inlineScript);

    return () => {
      document.head.removeChild(gtagScript);
      document.head.removeChild(inlineScript);
    };
  }, []);

  return (
    <div className="LandingPage">
      <LandingPageHeader />

      <div className="hero-display-web">
        <div className="hero-container">
          <h1>We Apply For You.</h1>
          <div className="subtitle">
            ApplyStorm accelerates your job search journey. Let us handle the hard part, while you
            continue to work on you.{' '}
          </div>

          <a href="#pricing">
            <Button className="blue-cta"> Get 20 jobs applied to for FREE!</Button>
          </a>
          <a href="#howitworks">
            <Button type='link'> Learn more</Button>
          </a>
        </div>

        <div>
          <img src={landingpageimage} alt="ApplyStorm" height={286} width={479} />
        </div>
      </div>
      <div className="seperator"></div>
      <FrustratedJobseekerSection />
      <div className="seperator"></div>
      <SeeDailyProgressSection />
      <div className="seperator"></div>
      <PersonalRecuiterSection />
      <div className="seperator"></div>
      <PricingPage />
      <div className="seperator"></div>
      <HowItWorksSection />
      <div className="seperator"></div>
    </div>
  );
};

export default LandingPage;
